import { useIntl } from "react-intl";

import { Button, Divider, Heading, Modal } from "@hexa-ui/components";

import * as Styles from "./CallSummaryActions.style";

export function CallSummaryActions() {
  const { formatMessage } = useIntl();

  return (
    <Styles.Container data-testid="CallSummaryActions">
      <Styles.Description>
        <Heading size="H4">
          {formatMessage({ id: "CallSummaryModal.NEXT_STEPS" })}
        </Heading>
        <Divider />
        <Styles.Paragraph size="small">
          {formatMessage({ id: "CallSummaryModal.NEXT_STEPS_DESCRIPTION" })}
        </Styles.Paragraph>
      </Styles.Description>

      <Styles.SubmitButtonContainer>
        <Modal.Cancel>
          <Button variant="secondary">
            {formatMessage({ id: "CallSummaryModal.TAKE_BREAK" })}
          </Button>
        </Modal.Cancel>
        <Modal.Action>
          <Button type="submit" data-testid="CallSummaryModal-action">
            {formatMessage({ id: "CallSummaryModal.CALL_NEXT_POC" })}
          </Button>
        </Modal.Action>
      </Styles.SubmitButtonContainer>
    </Styles.Container>
  );
}
