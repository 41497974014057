import { tokens } from "@bees-grow-shared/theme";
import { Paragraph as ParagraphHexa } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > button {
    &:first-child {
      color: ${tokens.color.foundation.txt.default};
      border-color: ${tokens.color.component.border.button.secondary.default};
    }
    &:last-child {
      background-color: ${tokens.color.foundation.txt.default};
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-2"]};
  margin-top: ${tokens.measure.space["spacing-2"]};

  & > h4 {
    color: ${tokens.color.foundation.txt.default};
  }
`;

export const Paragraph = styled(ParagraphHexa)`
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  color: ${tokens.color.foundation.txt.secondary};
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.measure.space["spacing-4"]};
  margin-top: ${tokens.measure.space["spacing-4"]};
`;
