import { useCallback, useEffect, useMemo } from "react";

import {
  accountSharedService,
  agentCallSharedService,
  phoneRankSharedService,
  PocCallOrigin,
  useDefaultAnalyticsData,
  useFeatureToggleEnabledByOrgId,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { TimerValue } from "@/@types/TimeValue";
import { GROW_PORTAL_START_CALL_AUTOMATICALLY } from "@/config/featureToggles";
import segmentAnalytics from "@/config/typewriter";
import { OpenCallStateEnum } from "@/constant/openCallStateEnum";
import { getCallAttempts } from "@/stores/openCall/OpenCallEvent";
import OpenCallStore from "@/stores/openCall/OpenCallStore";
import { upsertOpenCallUseCase } from "@/useCases/agentCall/upsertOpenCall/UpsertOpenCallUseCase";

import { useCallTimeSync } from "../useCallTimeSync/useCallTimeSync";
import { useCTIConnection } from "../useCTIConnection/useCTIConnection";

interface UseCallTimerInfoProps {
  ctiEnabled: boolean;
  timer: TimerValue;
  isLoading: boolean;
  isOnCall: boolean;
  isCTIConnected: boolean;
  attempts: number;
  toggleCallState: () => void;
}

export function useCallTimerInfo(
  onFinishCall?: () => void,
): UseCallTimerInfoProps {
  const phoneRankService = phoneRankSharedService();
  const { accountId, vendorId } = useSharedUnit(accountSharedService());
  const agentCallService = agentCallSharedService();
  const { callId, pocCallOrigin, startCallDate, openCall, ctiEnabled } =
    useSharedUnit(agentCallSharedService());
  const {
    isLoading: isLoadingPhoneRank,
    done: phoneRankDone,
    phones,
  } = useSharedUnit(phoneRankService);

  const { callAttempts, isModalOpen } = useUnit(OpenCallStore);

  const defaultData = useDefaultAnalyticsData();

  const { value, start } = useCallTimeSync(startCallDate);

  const { isCTIConnected, handleStartCTI } = useCTIConnection({
    phones,
    pocCallOrigin,
    phoneRankDone,
    isModalOpen,
  });

  const { isEnabled: isStartAutomaticallyEnabled } =
    useFeatureToggleEnabledByOrgId(GROW_PORTAL_START_CALL_AUTOMATICALLY);

  const isStartCallLoading = useMemo(
    () => (isLoadingPhoneRank || openCall.isLoading) && !openCall.done,
    [isLoadingPhoneRank, openCall.done, openCall.isLoading],
  );

  const isAutoDialer = useMemo(
    () => pocCallOrigin === PocCallOrigin.AUTO_DIALER,
    [pocCallOrigin],
  );

  const isGetNextClient = useMemo(
    () => pocCallOrigin === PocCallOrigin.GET_NEXT_CLIENT,
    [pocCallOrigin],
  );

  const isStartAutomatically = useMemo(
    () => isStartAutomaticallyEnabled && isGetNextClient,
    [isGetNextClient, isStartAutomaticallyEnabled],
  );

  const isStartedCall = useMemo(() => !!startCallDate, [startCallDate]);

  const initializeCall = useCallback(() => {
    const startCall = new Date();
    agentCallService.upsertOpenCall(
      upsertOpenCallUseCase({
        callId,
        vendorId,
        startCall,
        useCase: OpenCallStateEnum.START_CALL,
      }).then(() => {
        start();
        handleStartCTI();
      }),
    );
  }, [agentCallService, callId, handleStartCTI, start, vendorId]);

  const toggleCallState = () => {
    if (isStartedCall) {
      onFinishCall?.();
      segmentAnalytics.buttonClicked({
        button_label: "End Call",
        button_name: "end_call_button",
        url: null,
        search_query: null,
        screen_name: "POC Information",
        ...defaultData,
      });
    } else {
      initializeCall();
    }
  };

  useEffect(() => {
    if (callId && vendorId) {
      getCallAttempts({ accountId, vendorId });
    }
  }, [accountId, callId, vendorId]);

  useEffect(() => {
    if (
      (isAutoDialer || isStartAutomatically) &&
      phoneRankDone &&
      !startCallDate &&
      !isStartedCall &&
      callId &&
      phones.length > 0
    ) {
      initializeCall();
    }
  }, [
    initializeCall,
    isAutoDialer,
    isStartAutomatically,
    startCallDate,
    phoneRankDone,
    isStartedCall,
    callId,
    isStartCallLoading,
    phones.length,
  ]);

  return {
    isLoading: isStartCallLoading,
    timer: value,
    ctiEnabled,
    isCTIConnected,
    isOnCall: !!startCallDate,
    attempts: callAttempts,
    toggleCallState,
  };
}
