import { agentCallSharedService, CallLogs } from "@bees-grow-shared/services";

import { CallClassificationEnum } from "@/@types/CallClassification";
import { OpenCallStateEnum } from "@/constant/openCallStateEnum";
import { upsertOpenCall } from "@/services/agentCall/upsertOpenCall/UpsertOpenCallService";

interface UpsertOpenCallUseCaseProps {
  callId: string;
  vendorId: string;
  useCase: OpenCallStateEnum;
  startCall?: Date;
  endCall?: Date;
  duration?: number;
  callClassification?: CallClassificationEnum;
  callComment?: string;
  callLaterHour?: number;
  callLaterTime?: string;
  callLogs?: CallLogs[];
}

export const upsertOpenCallUseCase = async ({
  callId,
  vendorId,
  startCall,
  endCall,
  duration,
  useCase,
  callClassification,
  callComment,
  callLaterHour,
  callLaterTime,
  callLogs,
}: UpsertOpenCallUseCaseProps): Promise<void> => {
  const params = {
    path: { callId },
    query: { useCase },
    headers: { vendorId },
  };

  if (useCase === OpenCallStateEnum.START_CALL) {
    agentCallSharedService().setStartCallState({
      startCallDate: startCall.toISOString(),
      callId,
    });

    await upsertOpenCall(params, {
      startCall,
    });
  }

  if (useCase === OpenCallStateEnum.END_CALL) {
    agentCallSharedService().setStartCallState({
      startCallDate: "",
    });

    await upsertOpenCall(params, {
      endCall,
      duration,
      callClassification,
      callComment,
      callLaterHour,
      callLaterTime,
      callLogs,
    });
  }
};
