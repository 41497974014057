import { useIntl } from "react-intl";

import { ClipboardButton } from "@bees-grow-shared/components";

import { PocData } from "../../types/pocInformationTypes";
import * as Styles from "./GenericInformation.styles";

interface GenericInformationProps {
  basicData: PocData;
}

export default function GenericInformation({
  basicData,
}: GenericInformationProps) {
  const { formatMessage } = useIntl();
  const { title, value, hasClipboard } = basicData;

  return (
    <div data-testid={`${GenericInformation.name}`}>
      <Styles.Title
        weight="semibold"
        size="small"
        data-testid={`${GenericInformation.name}-title`}
      >
        {title}
      </Styles.Title>
      <Styles.WrapperValue>
        <Styles.Value data-testid={`${GenericInformation.name}-value`}>
          {value}
        </Styles.Value>
        {hasClipboard && (
          <ClipboardButton
            iconSize="small"
            testId={`${GenericInformation.name}-clipboard`}
            textToCopy={value}
            tooltipCopiedText={formatMessage({ id: "CopyButton.COPIED" })}
            tooltipCopyText={formatMessage({ id: "CopyButton.COPY" })}
          />
        )}
      </Styles.WrapperValue>
    </div>
  );
}
