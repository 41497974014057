import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  accountSharedService,
  agentCallSharedService,
  autoDialerSharedService,
  cartSharedService,
  ClassificationEnum,
  ctiSharedService,
  growSettingsSharedService,
  phoneRankSharedService,
  PhoneRequestType,
  PhoneResponseType,
  PhoneTypeEnum,
  PocCallOrigin,
  ScoreEnum,
  userConfigSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";
import { z } from "zod";

import { CallClassificationEnum } from "@/@types/CallClassification";
import { TimerValue } from "@/@types/TimeValue";
import { HOME_PATH } from "@/config/constants";
import { callEnded } from "@/config/typewriter";
import { callLaterOptions } from "@/constant/callLaterOptions";
import { FinisherButtonEnum } from "@/constant/finisherButtonEnum";
import { OpenCallStateEnum } from "@/constant/openCallStateEnum";
import { useCallTimerInfo } from "@/hooks/useCallTimerInfo/useCallTimerInfo";
import { setIsModalOpen } from "@/stores/openCall/OpenCallEvent";
import OpenCallStore from "@/stores/openCall/OpenCallStore";
import { upsertOpenCallUseCase } from "@/useCases/agentCall/upsertOpenCall/UpsertOpenCallUseCase";
import { calculateCallLaterTime } from "@/utils/calculateCallLaterTime/calculateCallLaterTime";
import { convertTimeInSecond } from "@/utils/convertTimeInSecond/convertTimeInSecond";

const baseCallSummarySchema = z.object({
  callClassification: z.nativeEnum(CallClassificationEnum),
  callLater: z.string().optional(),
  comments: z.string(),
  submitter: z.string().optional(),
});
const callSummarySchema = baseCallSummarySchema.superRefine((data, ctx) => {
  if (
    (data.callClassification === CallClassificationEnum.CALL_LATER_TODAY ||
      data.callClassification === CallClassificationEnum.NO_ANSWER_TRY_AGAIN) &&
    !data.callLater
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      params: { callClassification: data.callClassification },
      path: ["callLater"],
    });
  }
});

type CallSummarySchema = z.infer<typeof baseCallSummarySchema>;

interface UseCallSummaryModalProps {
  isModalOpen: boolean;
  pocCallOrigin: PocCallOrigin;
  ctiEnabled: boolean;
  cartDetails?: string;
  callTimerInfo: {
    attempts: number;
    isCTIConnected: boolean;
    timer: TimerValue;
  };
  callLaterOptions: {
    value: string;
    label: string;
  }[];
  callSummarySchema: z.ZodEffects<
    z.ZodObject<
      {
        callClassification: z.ZodNativeEnum<typeof CallClassificationEnum>;
        callLater: z.ZodOptional<z.ZodString>;
        comments: z.ZodString;
      },
      "strip",
      z.ZodTypeAny,
      {
        callClassification?: string;
        callLater?: string;
        comments?: string;
      }
    >
  >;
  actions: {
    handleSubmitCallSummary: (data: CallSummarySchema) => void;
    handleToggleModal: () => void;
    createNewContact: (
      phones: PhoneResponseType[],
      phoneNumber: string,
    ) => PhoneRequestType[];
  };
}

export function useCallSummaryModal(): UseCallSummaryModalProps {
  const { isModalOpen } = useUnit(OpenCallStore);
  const cartService = cartSharedService();
  const agentCallService = agentCallSharedService();
  const autoDialerService = autoDialerSharedService();
  const phoneRankService = phoneRankSharedService();
  const accountService = accountSharedService();
  const growSettingsService = growSettingsSharedService();
  const userConfigService = userConfigSharedService();
  const ctiService = ctiSharedService();

  const { details } = useSharedUnit(cartService);
  const { pocCallOrigin, callId, callLogs, ctiEnabled } =
    useSharedUnit(agentCallService);
  const { phoneAutoDialer } = useSharedUnit(autoDialerService);
  const { vendorId, accountId } = useSharedUnit(accountService);
  const { phones } = useSharedUnit(phoneRankService);
  const { minCallDuration } = useSharedUnit(growSettingsService);
  const { orgId } = useSharedUnit(userConfigService);
  const { isConnected: isCTIConnected } = useSharedUnit(ctiService);

  const navigate = useNavigate();

  const isAutoDialer = pocCallOrigin === PocCallOrigin.AUTO_DIALER;

  const handleToggleModal = useCallback(() => {
    setIsModalOpen({
      isModalOpen: !isModalOpen,
    });
  }, [isModalOpen]);

  const { attempts, timer } = useCallTimerInfo(handleToggleModal);

  useEffect(() => {
    growSettingsService.getGrowSettings({
      orgId,
      vendorId,
    });
  }, [growSettingsService, orgId, vendorId]);

  const createNewContact = useCallback(
    (phones: PhoneResponseType[], phoneNumber: string) => {
      const baseName = "New Contact";
      const suffix = phones.filter(({ phoneContact }) =>
        phoneContact?.startsWith(baseName),
      ).length;

      const newName = `${baseName}${suffix > 0 ? ` ${suffix}` : ""}`;

      return [
        {
          phoneNumber,
          classification: ClassificationEnum.INPUTTED,
          contactName: newName,
          phoneType: PhoneTypeEnum.INPUTTED_PHONE,
        },
      ];
    },
    [],
  );

  const handleCallClassification = useCallback(async () => {
    const foundPhone = phones.find(
      (phone: PhoneResponseType) => phone.phoneNumber === phoneAutoDialer,
    );

    if (!foundPhone) {
      const newContact = createNewContact(phones, phoneAutoDialer);

      await phoneRankService.postPhoneRank({
        params: {
          accountId,
          vendorId,
        },
        phones: newContact,
      });

      return;
    }

    const isInactive = foundPhone.score === ScoreEnum.INACTIVE;

    const updatedPhone: PhoneRequestType = {
      contactName: foundPhone.phoneContact,
      phoneNumber: phoneAutoDialer,
      classification: isInactive
        ? ClassificationEnum.ACTIVATE
        : ClassificationEnum.GOOD,
      phoneType: PhoneTypeEnum.INPUTTED_PHONE,
    };

    await phoneRankService.updatePhoneRank({
      params: {
        accountId,
        vendorId,
        phoneNumber: phoneAutoDialer,
      },
      phone: updatedPhone,
    });
  }, [
    accountId,
    vendorId,
    phoneAutoDialer,
    phoneRankService,
    phones,
    createNewContact,
  ]);

  const handleSubmitCallSummary = useCallback(
    (data: CallSummarySchema) => {
      const callLaterHour = parseFloat(data.callLater);
      const callLaterTime = calculateCallLaterTime(callLaterHour);
      const duration = convertTimeInSecond(timer);
      const endCall = new Date();

      /* eslint-disable camelcase */
      callEnded({
        call_origin: pocCallOrigin,
        contact_classification: data.callClassification,
        general_comments: data.comments,
        finisher_button: data.submitter,
      });

      const callIsValid =
        Math.floor(convertTimeInSecond(timer)) >= minCallDuration;

      const isSuccessContact =
        data.callClassification.valueOf() ===
        CallClassificationEnum.SUCCESS_ON_CONTACT.valueOf();

      if (isAutoDialer && isSuccessContact && callIsValid) {
        handleCallClassification();
      }

      handleToggleModal();

      upsertOpenCallUseCase({
        callId,
        vendorId,
        endCall,
        useCase: OpenCallStateEnum.END_CALL,
        duration,
        callClassification: data.callClassification,
        callComment: data.comments,
        callLaterHour,
        callLaterTime,
        callLogs,
      }).then(() => {
        agentCallService.resetStoresOnEndCall();

        if (ctiEnabled && isCTIConnected) {
          ctiService.close();
        }

        if (pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
          autoDialerService.setAutoDialerState({
            accountIdAutoDialer: "",
            vendorIdAutoDialer: "",
            phoneAutoDialer: "",
          });

          agentCallService.setStartCallState({
            hasStartedCall: true,
          });

          if (data.submitter === FinisherButtonEnum.TAKE_BREAK) {
            autoDialerService.setAutoDialerState({
              showStopCallingModal: true,
            });
          }
        }

        navigate(HOME_PATH);
      });
    },
    [
      agentCallService,
      autoDialerService,
      callId,
      callLogs,
      ctiEnabled,
      ctiService,
      handleCallClassification,
      handleToggleModal,
      isAutoDialer,
      isCTIConnected,
      minCallDuration,
      navigate,
      pocCallOrigin,
      timer,
      vendorId,
    ],
  );

  return {
    isModalOpen,
    ctiEnabled,
    callTimerInfo: { attempts, isCTIConnected, timer },
    cartDetails: details,
    callLaterOptions,
    pocCallOrigin,
    callSummarySchema,
    actions: {
      handleToggleModal,
      handleSubmitCallSummary,
      createNewContact,
    },
  };
}
