import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { Select } from "@hexa-ui/components";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  CallClassificationEnum,
  useCallSummaryModal,
} from "../../hooks/useCallSummaryModal";
import * as Styles from "./CallSummaryBody.style";
import { CallSummaryActions } from "./components/CallSummaryActions/CallSummaryActions";

export function CallSummaryBody() {
  const {
    callSummarySchema,
    callLaterOptions,
    actions: { handleSubmitCallSummary },
  } = useCallSummaryModal();

  const { formatMessage } = useIntl();

  const { control, handleSubmit, watch } = useForm({
    resolver: zodResolver(callSummarySchema),
    defaultValues: {
      callClassification: undefined,
      callLater: undefined,
      comments: undefined,
    },
  });

  const watchCallClassification = watch("callClassification");

  const wasNoAnswer =
    watchCallClassification === CallClassificationEnum.NO_ANSWER_TRY_AGAIN ||
    watchCallClassification === CallClassificationEnum.CALL_LATER_TODAY;

  return (
    <Styles.Form
      onSubmit={handleSubmit(handleSubmitCallSummary)}
      data-testid="CallSummaryBody"
    >
      <div>
        <Controller
          name="callClassification"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select.Root
              label={formatMessage({
                id: "CallSummaryModal.CONTACT_CLASSIFICATION",
              })}
              data-testid="CallSummaryBody-callClassification"
              {...field}
              placeholder={formatMessage({
                id: "CallSummaryModal.PLACEHOLDER",
              })}
            >
              {Object.keys(CallClassificationEnum).map((key) => (
                <Select.Option key={key} value={key}>
                  {formatMessage({
                    id: `CallSummaryModal.CallClassification.${key}`,
                  })}
                </Select.Option>
              ))}
            </Select.Root>
          )}
        />
      </div>
      {wasNoAnswer && (
        <div>
          <Controller
            name="callLater"
            control={control}
            rules={{ required: wasNoAnswer }}
            render={({ field }) => (
              <Select.Root
                label={formatMessage({
                  id: "CallSummaryModal.MAKE_CALL_LATER",
                })}
                placeholder={formatMessage({
                  id: "CallSummaryModal.PLACEHOLDER",
                })}
                data-testid="CallSummaryBody-callLater"
                {...field}
              >
                {callLaterOptions.map((option) => (
                  <Select.Option
                    key={option.label}
                    value={String(option.value)}
                  >
                    {formatMessage({
                      id: `CallSummaryModal.${option.label}`,
                    })}
                  </Select.Option>
                ))}
              </Select.Root>
            )}
          />
        </div>
      )}

      <div>
        <Controller
          name="comments"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Styles.TextArea
              width={"100%"}
              label={formatMessage({ id: "CallSummaryModal.COMMENTS" })}
              placeholder={formatMessage({
                id: "CallSummaryModal.COMMENTS_PLACEHOLDER",
              })}
              height={246}
              {...field}
            />
          )}
        />
      </div>

      <CallSummaryActions />
    </Styles.Form>
  );
}
