import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  SummaryError: {
    ALL: "Unable to load some information in this page.",
    GAP: "Unable to load the topline gap information.",
    REWARDS: "Unable to load Club B information. ",
    CREDIT: "Unable to load the credit information",
    RETRY: "Try again",
  },
  CopyButton: {
    COPY: "Copy",
    COPIED: "Copied",
  },
  TopLineGap: {
    TITLE: "Topline opportunity",
    GOAL_TEXT: "Goal",
    GAP_TEXT: "Gap",
    ERROR_MESSAGE: "Unable to load the topline gap information.",
  },
  PocInformation: {
    OWNER_NAME: "Owner",
    SHOW_LESS: "Show less",
    SHOW_MORE: "Show more",
    POC_INFORMATION_TITLE: "POC information",
    SALES_STRUCTURE_TITLE: "Sales structure",
    POC_ID: "POC ID",
    ERP_ID: "ERP ID",
    TAX_ID: "Tax ID",
    LIQUOR_LICENSE_NUMBER: "Liquor license",
    ACCOUNT_ID: "BEES account ID",
    EMAIL: "Email",
    FORMATTED_ADDRESS: "Address",
    SEGMENT: "Segment",
    SUB_SEGMENT: "Sub-segment",
    POTENTIAL: "Potential",
    DDC: "DDC",
    SALES_REPRESENTATIVE: "Sales representative",
    SALES_ORGANIZATION: "Sales organization",
    SALES_DIRECTION: "Sales direction",
    SALES_MANAGEMENT: "Sales management",
    SALES_SUPERVISION: "Sales supervision",
    SALES_ZONE: "Sales zone",
    CHANNEL: "Channel",
  },
  CreditAndPoints: {
    Leverage: {
      ClubB: {
        POINTS: "points",
        NOTENROLED: "Not enrolled",
        NOTAVAILABLE: "Not available",
        Error: {
          DESCRIPTION: "Unable to load the Club B information.",
          RETRY_TITLE: "try again",
        },
      },
    },
    Credit: {
      TITLE: "Available credit",
      TOTAL_LIMIT: "Total limit",
      CREDIT_USE: "Credit used",
      CUMULATIVE_DISCOUNT: "Cumulative discount",
      EXPIRATION_DATE: "Expiration date",
      ERROR_MESSAGE: "Unable to load the credit information.",
      OVERDUE: "Overdue",
      Payment: {
        INFORMATION: "Payment information",
        METHOD: "Payment method",
        PERIOD: "Payment period",
      },
      REFERENCE_ID: "Reference ID",
    },
  },
  CallTimerInfo: {
    START_CALL: "Start call",
    ATTEMPTS: "Attempts",
    ONLINE: "Online",
    OFFLINE: "Offline",
    ToastMessage: {
      CTI_CONNECTION_FAILED: "Failed to connect with a CTI.",
    },
  },
  CallSummaryModal: {
    CALL_SUMMARY: "Call summary",
    CALL_DURATION: "Duration:",
    NEXT_STEPS: "Next steps",
    NEXT_STEPS_DESCRIPTION: "Both options will register this task.",
    CALL_ATTEMPTS: "Attempts:",
    CONTACT_CLASSIFICATION: "Contact classification",
    MAKE_CALL_LATER: "Next call attempt",
    UNABLE_FINISH_CALL: "Unable to finish call.",
    REQUIRED_FIELD: "This field is required",
    TAKE_BREAK: "Take a break",
    GO_HOME: "Go home",
    CALL_NEXT_POC: "Call next POC",
    COMMENTS: "Comments",
    COMMENTS_PLACEHOLDER: "Type your comments here...",
    CONNECTED: "Online",
    DISCONNECTED: "Offline",
    PLACEHOLDER: "Select an option",
    CallClassification: {
      SUCCESS_ON_CONTACT: "Success on contact",
      NO_ANSWER_DO_NOT_CALL_AGAIN: "No answer - Do not call again",
      NO_ANSWER_TRY_AGAIN: "No answer - Try again",
      RESPONSIBLE_NOT_AVAILABLE: "Responsible not available",
      INCORRECT_PHONE_NUMBER: "Incorrect phone number",
      POC_CLOSED_DUPLICATE: "Poc closed or duplicated",
      CALL_LATER_TODAY: "Call later today",
    },
    CallTypification: {
      "15_MINUTES": "15 minutes",
      "30_MINUTES": "30 minutes",
      "1_HOUR": "1 hour",
      "1_HOUR_AND_30_MINUTES": "1 hour and 30 minutes",
      "2_HOURS": "2 hours",
      "2_HOUR_AND_30_MINUTES": "2 hours and 30 minutes",
      "3_HOURS": "3 hours",
      "3_HOUR_AND_30_MINUTES": "3 hours and 30 minutes",
      "4_HOURS": "4 hours",
      "4_HOUR_AND_30_MINUTES": "4 hours and 30 minutes",
      "5_HOURS": "5 hours",
      "5_HOUR_AND_30_MINUTES": "5 hours and 30 minutes",
      "6_HOURS": "6 hours",
      "6_HOUR_AND_30_MINUTES": "6 hours and 30 minutes",
      "7_HOURS": "7 hours",
      "7_HOUR_AND_30_MINUTES": "7 hours and 30 minutes",
      "8_HOURS": "8 hours",
    },
  },
};

export default enUS;
