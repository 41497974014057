import { useCallback, useEffect } from "react";

import {
  accountSharedService,
  agentCallSharedService,
  phoneRankSharedService,
  PocCallOrigin,
  useFeatureToggleEnabledByOrgId,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import { TimerValue } from "@/@types/TimeValue";
import { GROW_PORTAL_START_CALL_AUTOMATICALLY } from "@/config/featureToggles";
import {
  getCallCounter,
  setIsStartedCall,
  upsertOpenCall,
} from "@/stores/openCall/OpenCallEvent";
import OpenCallStore from "@/stores/openCall/OpenCallStore";

import { useCallTimeSync } from "../useCallTimeSync/useCallTimeSync";
import { useCTIConnection } from "../useCTIConnection/useCTIConnection";

interface UseCallTimerInfoProps {
  timer: TimerValue;
  isLoading: boolean;
  isCTIConnected: boolean;
  ctiEnabled: boolean;
  isOnCall: boolean;
  attempts: number;
  toggleCallState: () => void;
}

export function useCallTimerInfo(
  onFinishCall?: () => void,
): UseCallTimerInfoProps {
  const {
    isLoading: isLoadingPhoneRank,
    done: phoneRankDone,
    phones,
  } = useSharedUnit(phoneRankSharedService());
  const { callId, pocCallOrigin, startCallDate, ctiEnabled } = useSharedUnit(
    agentCallSharedService(),
  );
  const { accountId, vendorId } = useSharedUnit(accountSharedService());
  const {
    isLoading: isLoadingOpenCall,
    callAttempts,
    isStartedCall,
    isModalOpen,
  } = useUnit(OpenCallStore);

  const isStartCallLoading = isLoadingPhoneRank || isLoadingOpenCall;

  const { isCTIConnected, handleStartCTI } = useCTIConnection({
    phones,
    ctiEnabled,
    pocCallOrigin,
    phoneRankDone,
    isModalOpen,
  });

  const { value, start } = useCallTimeSync(startCallDate);
  const { isEnabled: isStartAutomaticallyEnabled } =
    useFeatureToggleEnabledByOrgId(GROW_PORTAL_START_CALL_AUTOMATICALLY);

  const initializeCall = useCallback(async () => {
    if (!startCallDate) {
      const startCallDate = new Date();
      const correctionInMilliseconds = 7 * 1000 + 431;
      const correctedStartCallDate = new Date(
        startCallDate.getTime() - correctionInMilliseconds,
      );

      await upsertOpenCall({
        callId,
        vendorId,
        startCall: correctedStartCallDate,
      }).then(() => {
        handleStartCTI();
        start();
        agentCallSharedService().setStartCallState({
          startCallDate: correctedStartCallDate.toISOString(),
          callId,
        });
      });
    }
  }, [callId, handleStartCTI, start, startCallDate, vendorId]);

  useEffect(() => {
    getCallCounter({ accountId, vendorId });
  }, [accountId, vendorId]);

  useEffect(() => {
    if (!!startCallDate && !isStartedCall) {
      setIsStartedCall({ isStartedCall: true });
    }
  }, [isStartedCall, startCallDate]);

  useEffect(() => {
    if (
      (pocCallOrigin === PocCallOrigin.AUTO_DIALER ||
        (isStartAutomaticallyEnabled &&
          pocCallOrigin === PocCallOrigin.GET_NEXT_CLIENT)) &&
      phoneRankDone &&
      !isStartedCall
    ) {
      setIsStartedCall({ isStartedCall: true });
      initializeCall();
    }
  }, [
    initializeCall,
    isStartAutomaticallyEnabled,
    isStartedCall,
    phoneRankDone,
    pocCallOrigin,
  ]);

  const toggleCallState = () => {
    if (isStartedCall) {
      onFinishCall?.();
    } else {
      setIsStartedCall({ isStartedCall: true });
      initializeCall();
    }
  };

  return {
    isLoading: isStartCallLoading,
    timer: value,
    isCTIConnected,
    ctiEnabled,
    isOnCall: isStartedCall,
    attempts: callAttempts,
    toggleCallState,
  };
}
