/* eslint-disable no-console */
import { useCallback } from "react";

import { useUnit } from "effector-react";
import { z } from "zod";

import { TimerValue } from "@/@types/TimeValue";
import { useCallTimerInfo } from "@/hooks/useCallTimerInfo/useCallTimerInfo";
import { setIsModalOpen } from "@/stores/openCall/OpenCallEvent";
import OpenCallStore from "@/stores/openCall/OpenCallStore";

export enum CallClassificationEnum {
  SUCCESS_ON_CONTACT = "SUCCESS_ON_CONTACT",
  NO_ANSWER_DO_NOT_CALL_AGAIN = "NO_ANSWER_DO_NOT_CALL_AGAIN",
  NO_ANSWER_TRY_AGAIN = "NO_ANSWER_TRY_AGAIN",
  RESPONSIBLE_NOT_AVAILABLE = "RESPONSIBLE_NOT_AVAILABLE",
  INCORRECT_PHONE_NUMBER = "INCORRECT_PHONE_NUMBER",
  POC_CLOSED_DUPLICATE = "POC_CLOSED_DUPLICATE",
  CALL_LATER_TODAY = "CALL_LATER_TODAY",
}

const callLaterOptions = [
  {
    value: "0.25",
    label: "CallTypification.15_MINUTES",
  },
  {
    value: "0.5",
    label: "CallTypification.30_MINUTES",
  },
  {
    value: "1",
    label: "CallTypification.1_HOUR",
  },
  {
    value: "1.5",
    label: "CallTypification.1_HOUR_AND_30_MINUTES",
  },
  {
    value: "2",
    label: "CallTypification.2_HOURS",
  },
  {
    value: "2.5",
    label: "CallTypification.2_HOUR_AND_30_MINUTES",
  },
  {
    value: "3",
    label: "CallTypification.3_HOURS",
  },
  {
    value: "3.5",
    label: "CallTypification.3_HOUR_AND_30_MINUTES",
  },
  {
    value: "4",
    label: "CallTypification.4_HOURS",
  },
  {
    value: "4.5",
    label: "CallTypification.4_HOUR_AND_30_MINUTES",
  },
  {
    value: "5",
    label: "CallTypification.5_HOURS",
  },
  {
    value: "5.5",
    label: "CallTypification.5_HOUR_AND_30_MINUTES",
  },
  {
    value: "6",
    label: "CallTypification.6_HOURS",
  },
  {
    value: "6.5",
    label: "CallTypification.6_HOUR_AND_30_MINUTES",
  },
  {
    value: "7",
    label: "CallTypification.7_HOURS",
  },
  {
    value: "7.5",
    label: "CallTypification.7_HOUR_AND_30_MINUTES",
  },
  {
    value: "8",
    label: "CallTypification.8_HOURS",
  },
];

const callSummarySchema = z.object({
  callClassification: z.string(),
  callLater: z.string().optional(),
  comments: z.string(),
});

type CallSummarySchema = z.infer<typeof callSummarySchema>;

interface UseCallSummaryModalProps {
  isModalOpen: boolean;
  ctiEnabled: boolean;
  callTimerInfo: {
    attempts: number;
    isCTIConnected: boolean;
    timer: TimerValue;
  };
  callLaterOptions: {
    value: string;
    label: string;
  }[];
  callSummarySchema: z.ZodObject<
    {
      callClassification: z.ZodString;
      callLater: z.ZodOptional<z.ZodString>;
      comments: z.ZodString;
    },
    "strip",
    z.ZodTypeAny,
    {
      callClassification?: string;
      callLater?: string;
      comments?: string;
    }
  >;
  actions: {
    handleSubmitCallSummary: (data: CallSummarySchema) => void;
    handleToggleModal: () => void;
  };
}

export function useCallSummaryModal(): UseCallSummaryModalProps {
  const { isModalOpen } = useUnit(OpenCallStore);

  const handleToggleModal = useCallback(() => {
    setIsModalOpen({
      isModalOpen: !isModalOpen,
    });
  }, [isModalOpen]);

  const { attempts, isCTIConnected, timer, ctiEnabled } =
    useCallTimerInfo(handleToggleModal);

  const handleSubmitCallSummary = useCallback((data: CallSummarySchema) => {
    console.log("end call", data);
  }, []);

  return {
    isModalOpen,
    ctiEnabled,
    callTimerInfo: { attempts, isCTIConnected, timer },
    callLaterOptions,
    callSummarySchema,
    actions: {
      handleToggleModal,
      handleSubmitCallSummary,
    },
  };
}
