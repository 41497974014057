import { useIntl } from "react-intl";

import { Modal } from "@hexa-ui/components";
import { X } from "@hexa-ui/icons";

import * as Styles from "./CallSummaryModal.styles";
import { CallSummaryBody } from "./components/CallSummaryBody/CallSummaryBody";
import { CallSummaryInfo } from "./components/CallSummaryInfo/CallSummaryInfo";

interface CallSummaryModalProps {
  open?: boolean;
  onCloseModal?: () => void;
}

export function CallSummaryModal({
  open = false,
  onCloseModal,
}: Readonly<CallSummaryModalProps>) {
  const { formatMessage } = useIntl();

  return (
    <Styles.ModalWrapper>
      <Modal.Root actions={null} open={open}>
        <div data-testid="CallSummaryModal">
          <Styles.Header>
            <Styles.Heading>
              {formatMessage({ id: "CallSummaryModal.CALL_SUMMARY" })}
            </Styles.Heading>
            <Styles.IconButton
              variant="tertiary"
              icon={() => <X size="tiny" key="close" />}
              size="small"
              data-testid={`CallSummaryModal-closeButton`}
              onClick={onCloseModal}
            />
          </Styles.Header>
          <div>
            <CallSummaryInfo />
            <CallSummaryBody />
          </div>
        </div>
      </Modal.Root>
    </Styles.ModalWrapper>
  );
}
